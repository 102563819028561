import React from 'react';
import './StudentReviews.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// Importing images from the Images folder
import Bharath from './Images/Bharath Pic2.jpg'; 
import Ifran from './Images/IrfanPic.jpg'; 
import Ifran1 from './Images/Ifran1.jpg'; 
import MuzzamillPic from './Images/MuzzamillPic.jpg'; 
import Parvez from './Images/Parvez.jpg'; 
import SreePapa from './Images/SreePapa.jpg'; 
import Sree from './Images/Sree.jpg'; 
import VijayPic from './Images/VijayPic.jpg'; 
import Manoj from './Images/ManojPic.jpg'; 
import Nisar from './Images/Mohammed Nisar Pic.jpg'; 
import Director from './Images/Director.jpg'; 
import Pavan from './Images/Pavan Pic.jpg'; 
import Slaman from './Images/Mohammed Slaman.jpg'; 
import gilan from './Images/gilan.jpg'; 
import Isabel from './Images/Isabel.jpg'; 

const studentReviews = [
  {
    name: "Ifran",
    course: "Java",
    review: "The Java course exceeded all my expectations! The instructors’ deep expertise and their ability to simplify complex concepts was remarkable. With hands-on projects and practical coding challenges, my skills grew significantly, and I now feel equipped to tackle real-world Java applications with confidence. This course was a game-changer for my coding journey!",
    photo: Ifran
  },
  {
    name: "Muzzamill",
    course: "Python",
    review: "Mastering Python always seemed out of reach, but Ahiraa Learning changed that. The structured curriculum, filled with interactive exercises, made every lesson engaging and understandable. With expert guidance, I progressed from a beginner to a confident Python programmer. I now feel empowered to use Python in both personal and professional projects. Highly recommend this course to anyone looking to dive into Python!",
    photo: MuzzamillPic
  },
  {
    name: "Parvez - Amazon",
    course: "Data Science",
    review: "I’m excited to announce my placement at Amazon, thanks to Ahiraa Learning’s Data Science course. The letter of recommendation played a crucial role in the placement process, and the deep learning concepts explained by my trainer provided me with a strong foundation. This course not only expanded my technical expertise but also gave me the confidence to succeed in a highly competitive environment. Grateful to the Ahiraa team for their unwavering support!",
    photo: Parvez
  },
  {
    name: "Bharath",
    course: "Artificial Intelligence",
    review: "AI felt intimidating at first, but this course broke it down into digestible pieces, using real-world applications that made everything click. The course went beyond theory, incorporating practical projects that helped me apply what I learned. Now, I feel fully capable of using AI in both personal innovations and professional settings. If you’re looking to demystify AI, this is the course for you!",
    photo: Bharath
  },
  {
    name: "Shakshi",
    course: "Machine Learning",
    review: "The Machine Learning course was nothing short of extraordinary! The instructors provided clear, step-by-step explanations of intricate algorithms, and the practical examples made it so easy to grasp. I’ve been able to apply my newfound knowledge directly into my work, driving real results in projects. This course has been a catalyst for my professional growth in the field of data science!",
    photo: SreePapa
  },
  
  {
    name: "Vijay",
    course: "Web Development",
    review: "I can confidently say that Ahiraa Learning’s Web Development course changed the trajectory of my career. The balance between theory and practical assignments was perfect. The instructors guided me through each technology with clarity, and now I have a solid portfolio of web projects. I’m fully equipped to tackle front-end and back-end development challenges!",
    photo: VijayPic
  },
  {
    name: "Sudhakar",
    course: "Stock Market",
    review: "The Stock Market course at Ahiraa Learning was absolutely phenomenal! It transformed my understanding of trading and investments. The instructors broke down complex financial concepts and strategies, making them accessible even for beginners. The practical trading simulations and real-time market analysis were invaluable, helping me gain the confidence to make informed investment decisions. I’m now seeing real results in my portfolio thanks to this course!",
    photo: Director
  },
  {
    name: "Sreenivas",
    course: "Web Development",
    review: "The Web Development course at Ahiraa Learning was simply outstanding! From HTML to advanced JavaScript, every concept was covered in depth, with hands-on projects that mirrored real-world applications. This course transformed me from a beginner into a full-fledged developer, ready to take on any web-based challenge. Highly recommended for aspiring developers!",
    photo: Sree
  },

  {
    name: "Irfan",
    course: "VLSI",
    review: "Ahiraa Learning’s VLSI course was exceptional in every way! The curriculum covered everything from digital design fundamentals to advanced chip design techniques. The hands-on labs were invaluable, allowing me to work with real-world tools used in the semiconductor industry. This course gave me a deep understanding of VLSI design and verification, and I now feel fully prepared to tackle complex VLSI projects. It has truly accelerated my career in hardware design!",
    photo: Ifran1
  },
  {
    name: "Pavan",
    course: "Java",
    review: "I had struggled with Java before, but this course made everything so clear. The instructors had a way of explaining even the most complicated concepts with ease. I loved the practical assignments and coding exercises that allowed me to apply what I learned immediately. Thanks to Ahiraa Learning, I’m now proficient in Java and ready to take on advanced projects.",
    photo: Pavan
  },
  {
    name: "Mohammed Nisar",
    course: "Web Development",
    review: "Joining Ahiraa Learning’s Web Development course was one of the best decisions I’ve made. I was introduced to a range of web technologies like React, Node.js, and more. The course wasn’t just about learning to code—it was about becoming a problem solver and understanding how to design user-friendly websites. The portfolio I built during the course helped me land interviews right away!",
    photo: Nisar
  },
  {
    name: "Manoj Masani",
    course: "Cloud Computing",
    review: "The Cloud Computing course at Ahiraa Learning was outstanding! It covered everything from cloud fundamentals to advanced deployment strategies. The hands-on experience with platforms like AWS and Azure gave me the confidence to design, implement, and manage cloud solutions. The real-world projects were particularly helpful in preparing me for the industry. I now feel ready to take on any cloud-related challenge in my career!",
    photo: Manoj
  },
  
  
  {
    name: "Mohammed Slaman",
    course: "Cybersecurity",
    review: "Ahiraa Learning’s Cybersecurity course was a game-changer for me! The curriculum was comprehensive, covering everything from network security to ethical hacking. The hands-on labs and real-world scenarios helped me gain practical skills that I can apply immediately. I now feel confident in identifying and mitigating security threats, and this course has opened up new career opportunities for me in the cybersecurity field. Highly recommended!",
    photo: Slaman
  },
  {
    name: "Jilan",
    course: "Java",
    review: "Ahiraa Learning’s Java course took my programming skills to the next level. The instructors broke down complex topics like JVM architecture and memory management into easy-to-understand lessons. The real-world projects were instrumental in cementing my understanding, and now I feel fully equipped to work on Java applications in any professional setting. Truly an excellent course!",
    photo: gilan
  },
  {
    name: "Isabel",
    course: "Cryptoc Currency",
    review: "Ahiraa Learning’s Crypto currency course was eye-opening! It provided a thorough understanding of blockchain technology, digital currencies, and how the crypto market operates. The real-world insights into trading, investing, and securing cryptocurrencies were invaluable. Thanks to this course, I now have the confidence to navigate the rapidly evolving world of digital finance and make informed decisions in the crypto space. A must-take for anyone interested in crypto!",
    photo: Isabel
  }
];


const StudentReviewCard = ({ name, course, review, photo }) => {
  return (
    <div className="student-card">
      <img src={photo} alt={name} className="student-photo" />
      <h3>{name}</h3>
      <h4>{course} Course</h4>
      <p>{review}</p>
    </div>
  );
};

const StudentReviewsSection = () => {
  return (
    <div className="student-review-container">
      <div className="student-reviews-wrapper">
        {studentReviews.concat(studentReviews).map((review, index) => (
          <StudentReviewCard
            key={index}
            name={review.name}
            course={review.course}
            review={review.review}
            photo={review.photo}
          />
        ))}
      </div>
    </div>
  );
};

export default StudentReviewsSection;
