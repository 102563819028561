import React, { useState } from 'react';
import axios from 'axios';
import './ApplicationForm.css'; // Custom styles for the form

const ApplicationForm = () => {
  // Form state management
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    jobRole: '',
    college: '',
    skills: '',
    resume: null,
  });

  const [status, setStatus] = useState('');

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle file upload
  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      resume: e.target.files[0],
    });
  };

  // Form submit handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Submitting...');

    // Create a FormData object to send data
    const formSubmissionData = new FormData();
    formSubmissionData.append('name', formData.name);
    formSubmissionData.append('phoneNumber', formData.phoneNumber);
    formSubmissionData.append('email', formData.email);
    formSubmissionData.append('jobRole', formData.jobRole);
    formSubmissionData.append('college', formData.college);
    formSubmissionData.append('skills', formData.skills);
    formSubmissionData.append('resume', formData.resume);

    try {
      // Sending data to the PHP backend
      const response = await axios.post('/backend/save_application.php', formSubmissionData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Handle the response from the server
      if (response.data.success) {
        setStatus('Application submitted successfully!');
      } else {
        setStatus(`Error: ${response.data.message}`);
      }
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      setStatus('Error submitting the form. Please try again.');
    }
  };

  return (
    <div className="application-form-container">
      <h2>Job Application Form</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label htmlFor="name">Full Name</label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input type="text" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email Address</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        
        <div className="form-group">
          <label htmlFor="jobRole">Mention Job Role for Applying :</label>
          <input type="text" id="jobRole" name="jobRole" value={formData.jobRole} onChange={handleChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="college">College Name</label>
          <input type="text" id="college" name="college" value={formData.college} onChange={handleChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="skills">Skills</label>
          <textarea id="skills" name="skills" value={formData.skills} onChange={handleChange} required />
        </div>

        <div className="form-group">
          <label htmlFor="resume">Resume (PDF, DOC, DOCX)</label>
          <input type="file" id="resume" name="resume" onChange={handleFileChange} accept=".pdf,.doc,.docx" required />
        </div>

        <button type="submit" className="btnAPPly btn-primary">Submit Application</button>

        <p className="status-message">{status}</p>
      </form>
    </div>
  );
};

export default ApplicationForm;
